import Grid from 'components/uiKit/grid';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
interface ContainerBorderRadiusProps {
  children: ReactNode;
  bgAbout?: string;
  bg?: string;
  className?: string;
}
export function ContainerBorderRadius({
  children,
  bgAbout = '#fff',
  bg = 'transparent',
  className
}: ContainerBorderRadiusProps) {
  const {
    pathname
  } = useRouter();
  return <Grid style={{
    background: bg,
    paddingTop: '0',
    minHeight: '2vh'
  }} data-sentry-element="Grid" data-sentry-component="ContainerBorderRadius" data-sentry-source-file="index.tsx">
      <Grid className={className ?? ''} style={{
      borderRadius: '1.5rem 1.5rem 0 0',
      background: bgAbout
    }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {children}
      </Grid>
    </Grid>;
}