import { textTranslated } from 'components/textTranslated';
import Button from 'components/uiKit/buttons';
import Typography from 'components/uiKit/typography';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
const TuohirullaBanner = () => {
  return <div className="lg:overflow-hidden lg:pt-10 lg:w-full" data-sentry-component="TuohirullaBanner" data-sentry-source-file="index.tsx">
      <div className="bg-primary rounded-[10px] lg:rounded-[20px] mx-5 relative pb-10 w-[calc(100%-40px)] lg:w-full lg:mx-0 lg:h-[320px]">
        <Image alt="burst" src="/assets/images/starburst.png" width={1000} height={1000} className="!w-full !h-auto absolute top-0 z-[7] scale-110 lg:hidden" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <Image alt="burst_lg" src="/assets/images/starburst-desktop.png" width={1282} height={291} className="!w-full !h-auto absolute top-0 z-[7] lg:block xs:hidden" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <Image alt="wheel" src="/assets/images/wheels-no-shadow.png" width={1000} height={1000} className="rotate-[14deg] !w-full !h-auto lg:!w-[540px] scale-105 relative -right-1 z-[8] lg:absolute lg:right-[5%] lg:-top-5" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <div className="text-white lg:text-left text-center lg:bg-[linear-gradient(180deg,rgba(74,38,255,0)_75%,#4A26FF_100%)] bg-[linear-gradient(180deg,rgba(74,38,255,0)_35%,#4A26FF_70%)] z-[9] absolute top-0 h-full w-full rounded-[10px] lg:rounded-[20px] flex flex-col lg:justify-center lg:pl-10 justify-end">
          <h4 className="text-28 font-bold lg:text-52 !mb-2">
            {textTranslated({
            group: 'home',
            key: 'tuohirullaBanner.title'
          })}
          </h4>
          <Typography translateGroup="home" translateKey="tuohirullaBanner.desc" className="!text-18 lg:!text-[26px] !mb-2.5 !w-[90%] lg:!w-[48%] !mx-auto lg:!mx-0 lg:!mb-5" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
          <Link href="/cashier/deposit" className="max-w-44 block lg:mx-0 lg:max-w-64 mx-auto mb-8 lg:mb-0" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <Button block id="tuohirulla-nosta-cta" color="yellow" className="lg:!h-[50px]" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              <Typography translateGroup="home" translateKey="tuohirullaBanner.cta" className="uppercase !text-14 lg:!text-xl !font-bold" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
            </Button>
          </Link>
        </div>
      </div>
    </div>;
};
export default TuohirullaBanner;