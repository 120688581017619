/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLobby } from 'components/lobby/useLobby';
import Grid from 'components/uiKit/grid';
import Typography from 'components/uiKit/typography';
import GlobalContext from 'context/global';
import React, { useContext, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'components/customLink';
import { breakPointsType } from 'utils/globalTypes';
import LastPlayedCarousel from 'components/lastPlayedCarousel';
import SupplierCarousel from 'components/supplierCarousel';
import AuthContext from 'context/auth';
import Button from 'components/uiKit/buttons';
import LoadingLobby from 'components/lobby/loadingLobby';
import GameCardV2 from 'components/cards/GameCardV2';
import ChevronDownIcon from 'assets/icons/chevronDownIcon';
import ChevronUpIcon from 'assets/icons/chevronUpIcon';
import TuohirullaBanner from 'components/tuohirullaBanner';
import styles from '../../../../layouts/legalPages/styles.module.scss';
interface Props {
  lobbyName: string;
}
const GameCarousel = dynamic(() => import('components/carousel/gameCarousel'), {
  ssr: false
});
export const cardsInView: { [key in breakPointsType]: number } = {
  xsm: 2.2,
  sm: 2.2,
  md: 4,
  lg: 6,
  xl: 6,
  xxl: 6
};
export default function DefaultLobby(props: Props) {
  const {
    state
  } = useContext(GlobalContext);
  const {
    isAuthenticated
  } = useContext(AuthContext);
  const {
    appSize
  } = state;
  const {
    lobbyName
  } = props;
  const {
    categories,
    isLoading
  } = useLobby(props);
  const [showText, setShowText] = useState(false);
  if (isLoading) {
    return <LoadingLobby />;
  }
  return <>
      <Grid gap="2rem" padding={['pt-5', 'pb-5']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {isAuthenticated && <LastPlayedCarousel />}
        {categories.map((category, index) => {
        const adjustedCardsInView = index === 1 || index === 3 ? cardsInView[appSize] as any || 2 : cardsInView[appSize] + 1 as any || 3;
        return <React.Fragment key={`defaultLobby-${category.name}-${lobbyName}-${index}`}>
              {/* Render the GameCarousel */}
              <GameCarousel gap={1} title={<Typography translateGroup="game-carousel" translateKey={category.name} weight={800} size="md" style={{
            textTransform: 'uppercase'
          }} />} viewAllButton={<Link href={`/${lobbyName}/${category.internalName}`}>
                    <Button id={`view-all-${category.name}`} color="outline-primary" size="xsm" styleButton="rounded">
                      <Typography translateGroup="game-carousel" translateKey="show-all" style={{
                fontSize: '0.875rem'
              }} />
                    </Button>
                  </Link>} cards={category.games.map(game => {
            return <GameCardV2 {...game} key={`${game.categoryId} - ${game.displayName}`} />;
          })} cardsInView={adjustedCardsInView} carouselId={`${category.name}-${lobbyName}`} enableDrag />

              {/* Insert SupplierCarousel after the 2nd GameCarousel */}
              {index === 1 && <SupplierCarousel />}

              {/* Insert TuohirullaBanner after the 4nd GameCarousel */}
              {index === 3 && !isAuthenticated && <TuohirullaBanner />}
            </React.Fragment>;
      })}

      </Grid>
      <div className="flex flex-col justify-center items-center w-full py-4">
        <h1 className="text-4xl font-[800] uppercase text-center relative overflow-hidden">
          Tuohi Kasino
          <span className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-50 animate-glimmer" />
        </h1>
        <Grid className={styles.wrapper} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <div className="flex flex-col justify-center items-center">
            <Typography translateGroup="homepage-seo" translateKey="text-short" size="normal" className="w-full md:w-2/3 px-4 md:p-0" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
            <div className={showText ? 'flex flex-col justify-center items-center' : 'hidden'}>
              <Typography translateGroup="homepage-seo" translateKey="text-long" size="normal" className="w-full md:w-2/3 px-4 md:p-0" data-sentry-element="Typography" data-sentry-source-file="index.tsx" />
            </div>

            <Button onClick={() => setShowText(!showText)} aria-expanded={showText} id="" color="outline-primary" styleButton="rounded" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              Näytä kaikki
              <span className="pl-2">
                {showText ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </span>
            </Button>
          </div>
        </Grid>
      </div>
    </>;
}