import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';
import gsap from 'gsap';
import Draggable from 'gsap/dist/Draggable';
import GlobalContext from 'context/global';
import Arrow from 'assets/icons/arrow';
import { twMerge } from 'tailwind-merge';
import styles from './styles.module.scss';
gsap.registerPlugin(Draggable);
type CardsInView = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type GapSize = 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2;
interface GameCarouselI {
  title: React.ReactNode;
  viewAllButton: React.ReactNode;
  cards: React.ReactNode[];
  cardsInView?: CardsInView;
  gap?: GapSize;
  carouselId: string;
  lastPlayed?: boolean;
  preDefinedCardWidth?: number;
  enableDrag?: boolean;
  enableArrows?: boolean;
}
const DEFAULT_CARDS_IN_VIEW = 6;
export default function GameCarousel({
  title,
  viewAllButton,
  cards,
  cardsInView = DEFAULT_CARDS_IN_VIEW,
  gap = 1,
  carouselId,
  lastPlayed,
  preDefinedCardWidth,
  enableDrag = true,
  enableArrows = true
}: GameCarouselI) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const cardWidthRef = useRef(0);
  const {
    appType
  } = useContext(GlobalContext);
  const [showLeftFade, setShowLeftFade] = useState(false);
  const [showRightFade, setShowRightFade] = useState(true);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isDesktop = appType === 'desktop';
  const isMobileOrTablet = ['mobile', 'tablet'].includes(appType);
  const updateFadeVisibility = useCallback(() => {
    if (!carouselRef.current) return;
    const {
      scrollLeft,
      scrollWidth,
      clientWidth
    } = carouselRef.current;
    setShowLeftFade(scrollLeft > 0);
    setShowRightFade(scrollLeft < scrollWidth - clientWidth);
  }, []);
  useEffect(() => {
    if (!carouselRef.current) return;
    let width = preDefinedCardWidth ?? 0;
    if (!width) {
      const cardElement = carouselRef.current.querySelector<HTMLElement>(`.${styles.card}`);
      width = cardElement ? cardElement.offsetWidth : 0;
    }
    cardWidthRef.current = width + gap * 16;
  }, [gap, preDefinedCardWidth]);
  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;
    const handleScroll = () => {
      updateFadeVisibility();
    };
    carousel.addEventListener('scroll', handleScroll);
    // eslint-disable-next-line consistent-return
    return () => carousel.removeEventListener('scroll', handleScroll);
  }, [updateFadeVisibility]);
  useEffect(() => {
    if (!enableDrag || !carouselRef.current || isMobileOrTablet) return;
    const draggableInstances = Draggable.create(carouselRef.current, {
      type: 'scrollLeft',
      edgeResistance: 0.5,
      inertia: true,
      throwProps: true,
      dragClickables: true,
      allowContextMenu: true,
      snap: value => Math.round(value / cardWidthRef.current) * cardWidthRef.current,
      onDrag: updateFadeVisibility,
      onThrowUpdate: updateFadeVisibility,
      onDragEnd: updateFadeVisibility
    });
    // eslint-disable-next-line consistent-return
    return () => {
      draggableInstances.forEach(instance => instance.kill());
    };
  }, [enableDrag, isMobileOrTablet, updateFadeVisibility]);
  const scrollCarousel = (direction: 'left' | 'right') => {
    if (!carouselRef.current) return;
    const carousel = carouselRef.current;
    const offset = cardWidthRef.current * cardsInView;
    const targetScrollLeft = direction === 'left' ? carousel.scrollLeft - offset : carousel.scrollLeft + offset;
    gsap.to(carousel, {
      scrollLeft: targetScrollLeft,
      duration: 0.8,
      ease: 'power2.inOut',
      onUpdate: updateFadeVisibility
    });
  };
  const cardWidthStyle = preDefinedCardWidth ? `${preDefinedCardWidth}px` : `calc((100% - ${gap * (cardsInView - 1)}rem) / ${cardsInView})`;
  const arrowButtonClass = twMerge('w-[75px] h-full bg-[rgba(74,38,255,0.75)] text-white absolute top-0 z-50 flex items-center justify-center cursor-pointer focus:outline-none', styles.leftRightButtons);
  return <div className={styles.wrapper} data-sentry-component="GameCarousel" data-sentry-source-file="index.tsx">
      <div className={styles.header}>
        <div className={styles.title} style={{
        width: lastPlayed ? '100%' : '50%'
      }}>
          {title}
        </div>
        {!lastPlayed && <div className={styles.viewAll}>{viewAllButton}</div>}
      </div>

      <div className={styles.content} onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
        {isDesktop && <>
            <div className={`${styles.leftFade} ${showLeftFade ? styles.showFade : ''}`} />
            <div className={`${styles.rightFade} ${showRightFade ? styles.showFade : ''}`} />

            {enableArrows && <>
                <button type="button" aria-label="Scroll left" className={twMerge(arrowButtonClass, 'left-0 rounded-tl-[12pt] rounded-bl-[12pt]', isMouseOver && showLeftFade ? styles.visible : '')} onClick={() => scrollCarousel('left')}>
                  <Arrow className="-rotate-180" />
                </button>
                <button type="button" aria-label="Scroll right" className={twMerge(arrowButtonClass, 'right-0 rounded-tr-[12pt] rounded-br-[12pt]', isMouseOver && showRightFade ? styles.visible : '')} onClick={() => scrollCarousel('right')}>
                  <Arrow />
                </button>
              </>}
          </>}

        <div ref={carouselRef} className={styles.carouselWrapper} style={{
        overflowX: isMobileOrTablet ? 'scroll' : 'hidden',
        scrollBehavior: isMobileOrTablet ? 'smooth' : 'unset'
      }} role="region" aria-label="Game carousel">
          <div className={styles.carouselContent}>
            {cards.map((card, index) => <div key={`${carouselId}-card-${index}`} className={styles.card} style={{
            width: cardWidthStyle
          }} role="group" aria-label={`Game ${index + 1}`}>
                {card}
              </div>)}
          </div>
        </div>
      </div>
    </div>;
}